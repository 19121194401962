import React from "react";
import "./App.css";
// import Loading from "./ReactRouter/Loading";
// import Home from "./ReactRouter/Home";

// const Feeling = {
//   Sad: 1,
//   Neutral: 2,
//   Happy: 3,
// };

// const feelingColorMap = {
//   [Feeling.Sad]: ["#0f0d16", "#738385", "#0f0d16"],
//   [Feeling.Neutral]: ["#0f0d16", "#4d94ff", "#0f0d16"],
//   [Feeling.Happy]: ["#0f0d16", "#d8021e", "#540510"],
// };

// const feelingLabelMap = {
//   [Feeling.Sad]: "Could be better ",
//   [Feeling.Neutral]: "Okay",
//   [Feeling.Happy]: "I'm on fire!",
// };

function App() {
  // const wrapperRef = useRef(null);
  // const [feeling, setFeeling] = useState(Feeling.Neutral);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Simulate loading delay (you can replace this with actual loading logic)
  //   const loadingTimeout = setTimeout(() => {
  //     setLoading(false);
  //   }, 3500);

  //   // Clear the timeout on component unmount to avoid memory leaks
  //   return () => clearTimeout(loadingTimeout);
  // }, []);

  // useEffect(() => {
  //   if (!wrapperRef.current) return;

  //   const [a, b, c] = feelingColorMap[feeling];

  //   wrapperRef.current.style.setProperty("--color-a", a);
  //   wrapperRef.current.style.setProperty("--color-b", b);
  //   wrapperRef.current.style.setProperty("--color-c", c);
  // }, [feeling]);

  return (
    <>
      {/* <main className="flex">
        <div ref={wrapperRef} className="wrapper relative">
          <div className="contentt relative">
            <h1 className="titlee ">How are you feeling today?</h1>
            <h2 className="label ">{feelingLabelMap[feeling]}</h2>
            <input
              className="range"
              onChange={(ev) => setFeeling(Number(ev.target.value))}
              type="range"
              min={1}
              value={feeling}
              max={3}
              step={1}
            />
          </div>
        </div>
      </main>

      <div className={`App ${loading ? "loading" : ""}`}>
        <div className="background-container">
          <div className="background-bubble"></div>
        </div>

        {loading ? <Loading /> : <Home />}
      </div> */}
          <iframe src="/coming_soon.html" title="Coming Soon Page" style={{ width: '100%', height: '100vh', border: 'none' }} />

    </>
  );
}

export default App;
